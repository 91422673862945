<template>
  <div id="app">
    <Prospect />
  </div>
</template>

<script>
import Prospect from './components/prospect.vue'
export default {
  name: 'App',
  components: {
    Prospect
  }
}
</script>


<style >
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #212121;
  background-color: white;
  font-family: 'Noto Sans SC', sans-serif;
  font-style: normal;
  height: 100vh;
  overflow-x: hidden;
}


.app-tabbar .van-tabbar {
  box-shadow: 0 0 10px #f2f3f4;
}

.body {
  max-width: 428px;
  min-width: 375px;
}
</style>
