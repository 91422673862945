import Vue from 'vue'
import App from './App.vue'

import 'vant/lib/index.css';

import Prospect from '../src/components/prospect.vue'
Vue.component('Prospect',Prospect)

import { Calendar, NavBar,Icon, List,Row,Col,Cell, Dialog, Image,Swipe,SwipeItem,Empty} from 'vant'

Vue.component('van-calendar', Calendar)
Vue.component('van-nav-bar', NavBar)
Vue.component('van-icon',Icon)
Vue.component('van-list',List)
Vue.component('van-row',Row)
Vue.component('van-col',Col)
Vue.component('van-cell',Cell)
Vue.component('van-dialog',Dialog)
Vue.component('van-image',Image)
Vue.component('van-swipe',Swipe)
Vue.component('van-swipe-item',SwipeItem)
Vue.component('van-empty',Empty)

Vue.config.productionTip = false


new Vue({
  render: h => h(App),
  
}).$mount('#app')
