import request from '@/utils/request'

/**
 * 查询获客助手数据
 * @param {string} date
 * @param {string} groupId 
 * @returns 
 */
export function getAcquisitionData(params) {
  return request({
    url: '/wxcp/customerAcquisitionLink/dataView',
    method: 'get',
    params
  })
}
