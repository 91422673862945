<template>
  <div style="position:relative;height: 100%;">
    <!-- 顶部安全区 -->
    <div class="van-safe-area-top" />
    <div class="layout">
      <div class="calendar" @click="chooseDate">
        {{ date }}<van-icon name="arrow-down" />
      </div>
    </div>
    
    <div class="list-layout">
      <van-empty v-if="list.length <= 0" description="暂无数据" />
      <van-list
        v-else
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div v-for="(item, index) in list" :key="`${item.id} + ${index}`" class="list-item" :class="{'gross': item.gross}">
          <van-row>
            <van-col span="10" class="max-1-line">
              {{ item.name }}
            </van-col>
            <van-col span="7" style="text-align: center;">
              <span class="sort-layout" @click="sortBySeq('dayCnt')">
                {{ item.dayCnt }}
                <sort-icon v-if="index === 0" :property="'dayCnt'" :order-by="query.orderBy" :order-seq="query.orderSeq" @sort="sortResult" />
              </span>
            </van-col>
            <van-col span="7" style="text-align: center;">
              <span class="sort-layout" @click="sortBySeq('monthCnt')">
                {{ item.monthCnt }}
                <sort-icon v-if="index === 0" :property="'monthCnt'" :order-by="query.orderBy" :order-seq="query.orderSeq" @sort="sortResult" />
              </span>
            </van-col>
          </van-row>
        </div>
      </van-list>
    </div>
    <van-dialog v-model="visible" :show-confirm-button="false" :close-on-click-overlay="true" @open="openDialog">
      <!-- :max-date="maxDate" -->
      <van-calendar
        id="calendar"
        ref="calendar"
        title="选择日期"
        :poppable="false"
        :min-date="minDate"
        :show-confirm="false"
        :style="{ height: '500px' }"
        allow-same-day
        @confirm="selectDate"
      />
    </van-dialog>
    <Marque v-if="imgLoad" class="sign">
      <div>
        <van-image width="16" height="16" :src="require('@/assets/images/celebrate.png')" style="transform: rotateY(180deg);" @error="imgError" /> 祝您天天爆量! <van-image width="16" height="16" :src="require('@/assets/images/celebrate.png')" @error="imgError" />
      </div>
    </Marque>
  </div>
</template>

<script>
import { Dialog } from 'vant'
import Marque from './marque/index.vue'
import SortIcon from './sort/SortIcon.vue'
import { getTodayDate, param2Obj,getUrlCode } from '@/utils'
import { formatterDate } from '@/utils/date'
import { getAcquisitionData } from '@/api'
export default {
  name: 'Prospect',
  components:{
    [Dialog.Component.name]: Dialog.Component,
    Marque, SortIcon
  },
  data(){
    return {
      visible: false,
      show: true,
      loading: false,
      finished: false,
      defaultDate: new Date(),
      date: getTodayDate(),
      minDate: new Date(2022, 0, 1),
      maxDate: new Date(getTodayDate()),
      imgLoad: true,
      query: {
        orderSeq: 'DESC',
        orderBy: 'dayCnt'
      },
      list: [],
    }
  },
  mounted(){
    this.onLoad()
  },
  methods: {
    chooseDate(){
      this.visible = true
    },
    selectDate(date){
      this.visible = false
      this.date = formatterDate(date,'yyyy-MM-dd',true)
      this.onLoad()
    },
    openDialog(){
      this.$nextTick(() => {
        this.date = getTodayDate()
        setTimeout(() => {
          this.$refs.calendar.scrollToDate(new Date())
        },250)
      })
    },
    imgError(){
      this.imgLoad = false
    },
    sortBySeq(orderPro){
      this.query.orderSeq = this.query.orderSeq === 'ASC' ? 'DESC' : 'ASC'
      this.query.orderBy = orderPro
      this.sortFunc()
    },
    sortResult({ orderBy,orderSeq }) {
      this.query.orderBy = orderBy
      this.query.orderSeq = orderSeq
      this.sortFunc()
    },
    sortFunc(){
      var self = this
      var sortedList = this.list.slice(2).sort(function(a, b) {
        var valueA = self.query.orderBy === 'dayCnt' ? a.dayCnt : a.monthCnt;
        var valueB = self.query.orderBy === 'dayCnt' ? b.dayCnt : b.monthCnt;
        var sortFactor = self.query.orderSeq === 'DESC' ? -1 : 1;
        return sortFactor * (valueA - valueB);
      });
      this.list.splice(2, this.list.length - 2, ...sortedList);
    },
    onLoad() {
      const groupId = getUrlCode(window.location.pathname) || ''
      // const groupId = getUrlCode('cacq.sit.meibankeji.com/3')
      this.loading = true
      getAcquisitionData({ groupId, date: this.date }).then(({ data }) => {
        this.list = data
        if(this.list && this.list.length > 0){
          this.list[0].name = `[${this.list[0].name}]总加`
          this.$set(this.list[0],'gross',true)
        }
        this.list.unshift({
          name: '名称',
          dayCnt: '当日添加',
          monthCnt: '当月添加'
        })
        
        this.sortFunc()
      }).catch((err) => {
        
      }).finally(() => {
        this.finished = true
        this.loading = false
      });
    },
  },

}
</script>

<style scoped>
.calendar {
  width: 140px;
  min-height: 32px;
  line-height: 32px;
  padding: 3px 16px;
  font-size: 16px;
  letter-spacing: 0.6px;
  font-family: DINAlternate-Bold, SimHei;
}
.layout{
  padding: 16px 0px 0px;
}

.list-layout {
  font-family: DINAlternate-Bold, SimHei;
  margin: 4px 16px;
  line-height: 42px;
  min-height: 60vh;
}

.list-item {
  border-radius: 8px;
  padding: 4px 16px;
  font-size: 16px;
  font-weight: 600;
}

.list-item:nth-child(1) {
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 14px !important;
  font-weight: 600;
  border-radius: 0px;
  background-color: rgb(240, 240, 240) !important;
}
.gross{
  color: red;
  font-weight: 600;
}

/* .list-item:nth-child(2) {
  color: red;
  font-weight: 600;
} */

.list-item:nth-of-type(2n + 1) {
  background-color: #fcf8f8;
}

.sign {
  text-align: center;
  margin-top: 120px;
  font-size: 14px;
  padding-bottom: 40px;
}

.max-1-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  cursor: pointer;
}
.sort-layout{
  width: 50%;
  margin: 0 auto;
  position: relative;
  font-size: 12px;
  font-weight: 600;
}

</style>