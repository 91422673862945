/**
 * 获取昨天日期 (yyyy-MM-dd)
 * @returns {string} 昨天的日期
 */
export function getYesterdayDate() {
  const today = new Date()
  const yesterday = new Date(today)
  yesterday.setDate(yesterday.getDate() - 1)

  const year = yesterday.getFullYear()
  const month = String(yesterday.getMonth() + 1).padStart(2, '0')
  const day = String(yesterday.getDate()).padStart(2, '0')

  const formattedDate = `${year}-${month}-${day}`
  return formattedDate
}

/**
 * 获取当前周的礼拜一的日期 (yyyy-MM-dd)
 * @returns {string} 当前周礼拜一的日期
 */
export function getMondayOfCurrentWeek() {
  const today = new Date()
  const dayOfWeek = today.getDay()
  const diff = today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1) // 获取本周礼拜一的日期

  const monday = new Date(today.setDate(diff))
  const year = monday.getFullYear()
  const month = String(monday.getMonth() + 1).padStart(2, '0')
  const day = String(monday.getDate()).padStart(2, '0')

  const formattedDate = `${year}-${month}-${day}`
  return formattedDate
}

/**
 * 获取今天的日期
 * @returns 
 */
export function getTodayDate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}


/**
 * query请求路径参数转为对象
 * @param {*} url
 */
export function param2Obj(url) {
  url = url === null ? window.location.href : url

  let search = decodeURIComponent(url).split('?')[1]
  search = search && search.split('#')[0]
  if (!search) {
    return {}
  }
  return JSON.parse(
    '{"' +
    search
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"') +
    '"}'
  )
}

/**
 * 非空判断，不是null 或 空字符串
 * @param {Any} value 
 * @returns Boolean 是否not null
 */
export function notNullandEmpty (value) {
  return value !== null && trim(value) !== '' && value !== undefined
}
/**
 * 判断变量是否为空对象或空字符串
 * @param {*} value - 要判断的变量
 * @returns {boolean} 变量是否为空对象或空字符串
 */
 export function isEmpty (value) {
  if(typeof value === 'undefined' || value === null) return true
  if(Array.isArray(value)){
    return value.length === 0;
  } else if (typeof value === 'object' && value !== null) {
    // 判断是否为空对象
    return Object.keys(value).length === 0 && value.constructor === Object;
  } else if (typeof value === 'string') {
    // 判断是否为空字符串
    return value.trim() === '';
  }
  return false;
}
/**
 * 获取域名后/的参数
 * getUrlCode(cacq.sit.meibankeji.com/QzCQohYvE62) = QzCQohYvE62
 * @param {string} url 目标url
 * @returns 
 */
export function getUrlCode(url) {
  const pathname = url ||  window.location.pathname;
  if(isEmpty(pathname)) return ''
  const regex = /\/+([^/]+)/;
  const match = pathname.match(regex);
  if (match) {
    const value = match[1];
    return value;
  }
  return '';
}