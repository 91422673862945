
/**
 * 格式化日期
 * @param {*} date 日期
 * @param {*} formatter 格式
 * @param {*} isPad 是否补0，默认true
 * @returns 
 */
export function formatterDate(date,formatter,isPad = true){
  const formater = _formatNormalize(formatter)
  const dateInfo = {
    yyyy: date.getFullYear(),
    MM: date.getMonth() + 1,
    dd: date.getDate(),
    HH: date.getHours(),
    mm: date.getMinutes(),
    ss: date.getSeconds(),
  }
  dateInfo.yyyy = isPad ? String(dateInfo.yyyy).padStart(4, "0") : dateInfo.yyyy
  dateInfo.MM = isPad ? String(dateInfo.MM).padStart(2, "0") : dateInfo.MM
  dateInfo.dd = isPad ? String(dateInfo.dd).padStart(2, "0") : dateInfo.dd
  dateInfo.HH = isPad ? String(dateInfo.HH).padStart(2, "0") : dateInfo.HH
  dateInfo.mm = isPad ? String(dateInfo.mm).padStart(2, "0") : dateInfo.mm
  dateInfo.ss = isPad ? String(dateInfo.ss).padStart(2, "0") : dateInfo.ss
  return formater(dateInfo)
}

/**
 * 日期格式归一
 * @param {*} formatter 
 * @returns 
 */
function _formatNormalize(formatter){
  if(typeof formatter === 'function'){
    return formatter;
  }
  if(typeof formatter !== 'string'){
    throw new TypeError('formatter must be string or function')
  }
  if(formatter === 'date'){
    formatter = 'yyyy-MM-dd'
  }else if(formatter === 'datetime'){
    formatter = 'yyyy-MM-dd HH:mm:ss'
  }
  return (dateInfo) => {
    const { yyyy, MM , dd, HH,mm,ss} = dateInfo
    return formatter.replace('yyyy',yyyy)
           .replace('MM',MM)
           .replace('dd',dd)
           .replace('HH',HH)
           .replace('mm',mm)
           .replace('ss',ss)
  }
}