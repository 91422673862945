<template>
  <div class="icon-container" @click="sortBy">
    <van-icon name="arrow-up" :class="{'active': orderStatus('ASC') }" @click="sortByTime('ASC')" />
    <van-icon name="arrow-down" :class="{'active': orderStatus('DESC') }" @click="sortByTime('DESC')" />
  </div>
</template>

<script>
import { Icon } from 'vant';
export default {
  name: 'SortIcon',
  components: {
    [Icon.name]: Icon,
  },
  props: {
    orderBy: {
      type: String,
      default: ''
    },
    orderSeq: {
      type: String,
      default: 'ASC'
    },
    property: {
      type: String,
      default: ''
    }
  },
  computed: {
    orderStatus() {
      const self = this;
      return function(seq) {
        if (self.property === '') return self.orderSeq === seq;
        return self.property === self.orderBy && self.orderSeq === seq;
      }
    }
  },
  methods: {
    sortBy(){
      
    },
    sortByTime(orderSeq) {
      this.$emit('sort', { orderBy: this.property, orderSeq: orderSeq });
    }
  }
};
</script>


<style scoped>
.icon-container {
  position:absolute;
  right: -13px;
  top: -4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
}
i{
    background-color: #f1f1f1;
    margin: 1px 0px;
    border-radius: 2px;
  }
  .active{
    color: white;
    background-color: rgba(69,110,255,0.8);
  }
</style>